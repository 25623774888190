* {
  box-sizing: border-box;
  font-family: Inter, sans-serif;
}

html {
  font-family: Inter, sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3 {
  margin: 0;
}

a {
  text-decoration: none;
  cursor: pointer;
}

img {
  border-style: none;
}

table {
  border-spacing: 0;
}

input {
  border: none;
}

input:focus {
  outline: none;
}

.page-padding {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.app {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.loader-main-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30vh;
}

.loader-main-container-card {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 50px;
  height: 50px;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
}

.loader-main-container .loader-main,
.loader-main-container-bg .loader-main,
.loader-main-container-card .loader-main {
  width: 30px;
}

.header-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  color: #6b6978;
}

.header-container .header-logo {
  margin-top: 60px;
}

.header-container .header-logo img {
  width: 80px;
}

.header-container .header-title {
  max-width: 60ch;
  padding: 3rem 1rem 0;
  font-size: 2rem;
  line-height: 1;
  margin: 0 auto 3rem;
  text-align: center;
  color: #27224e;
}

.header-container .header-title .header-subtitle {
  font-size: 16px;
}

.footer-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  width: 100%;
  padding: 50px;
  font-size: 13px;
  background-color: #27224e;
  color: #fff;
}

.footer-container a {
  color: #fff;
}

.crypto-politicos-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 50px;
}

.crypto-politicos-container .search-container {
  display: flex;
  margin-bottom: 24px;
  border: 1px solid #dcdce3;
  border-radius: 4px;
}

.crypto-politicos-container .search-container .search-input {
  padding: 9px 0 9px 12px;
}

.crypto-politicos-container .search-container .search-icon {
  display: flex;
  padding: 9px 12px;
  cursor: pointer;
}

.crypto-politicos-container .search-container .search-icon img {
  width: 16px;
}

.crypto-politicos-container .crypto-politicos-table {
  width: 70%;
  max-width: 1100px;
  border: 1px solid #dcdce3;
  border-radius: 8px;
  /* border-collapse: collapse; */
  border-collapse: initial;
  table-layout: fixed;
  overflow: hidden;
}

.crypto-politicos-container .crypto-politicos-table .menu-row {
  position: sticky;
  top: 0;
  text-transform: uppercase;
  font-size: 10px;
  line-height: 1.3;
  background: #fff;
  cursor: pointer;
}

.crypto-politicos-container .crypto-politicos-table .menu-row img {
  height: 8px;
  margin-left: 4px;
  width: 8px;
}

.crypto-politicos-container .crypto-politicos-table .table-row td {
  font-weight: 500;
  font-size: 13px;
  line-height: 1.5;
  text-decoration: none;
}

.crypto-politicos-container .crypto-politicos-table .table-row td.asset-cell {
  max-width: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.crypto-politicos-container .crypto-politicos-table .menu-row th,
.crypto-politicos-container .crypto-politicos-table .table-row td {
  text-align: right;
  width: 25%;
  height: 50px;
  padding-right: 24px;
  border-top: 1px solid #dcdce3;
  color: #6b6978;
}

.scroll-tooltip::-webkit-scrollbar {
  width: thin;
}

.scroll-tooltip {
  height: 100px;
  overflow: auto;
  scrollbar-width: thin;
}

.no-data-message {
  margin-top: 120px;
  margin-bottom: 96px;
  font-size: 14px;
  color: #6b6978;
}

.crypto-politicos-container .crypto-politicos-table .menu-row th {
  border-top: none;
}

.crypto-politicos-container .crypto-politicos-table .menu-row th:first-of-type,
.crypto-politicos-container
  .crypto-politicos-table
  .table-row
  td:first-of-type {
  padding-left: 1rem;
  text-align: left;
}

.pagination-wrapper-display-none {
  display: none;
}

.pagination-wrapper {
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 24px;
  padding: 0;
}

.pagination-wrapper .pagination-component {
  align-items: center;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  height: 30px;
  justify-content: center;
  margin-left: 4px;
  text-decoration: none;
  width: 30px;
}

.pagination-wrapper .pagination-component .pagination-text {
  font-size: 11px;
  font-weight: 400;
  color: #6b6978;
  line-height: 1.2;
  text-decoration: none;
}

li.pagination-component.selected {
  border-color: #000;
  color: #000;
  font-weight: 700;
}

.disabled {
  background-color: #dcdce3;
}

.marquee-item {
  margin: 10px 10px;
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  color: #000;
}

.marquee-item .info-bg {
  background-color: #68ffbf;
}

.ranking-container {
  display: flex;
  justify-content: space-between;
  gap: 24px;
  max-width: 1100px;
  width: 70%;
  margin-bottom: 24px;
}

.ranking-container .ranking-card {
  position: relative;
  padding: 6px 8px;
  width: 100%;
  border: 1px solid #dcdce3;
  border-radius: 8px;
}

.ranking-container .ranking-card .ranking-title {
  font-size: 16px;
  margin: 10px 8px;
  color: #6b6978;
}

.ranking-container .ranking-card .ranking-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.ranking-container .ranking-card .ranking-list .ranking-item {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  padding: 0 8px 10px 8px;
  color: #6b6978;
}

.ranking-container .ranking-card .ranking-list .ranking-item .bg-green {
  color: #000;
  background-color: #68ffbf7a;
}

@media (max-width: 425px) {
  .ranking-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .ranking-container .ranking-card .ranking-list .ranking-item {
    font-size: 12px;
  }
}


@media (max-width: 768px) {
  .ranking-container {
    width: 100%;
  }

  .crypto-politicos-container .crypto-politicos-table {
    width: 100%;
    display: block;
    border: unset;
    overflow-x: auto;
    white-space: nowrap;
  }

  .crypto-politicos-container
    .crypto-politicos-table
    .menu-row
    th:first-of-type,
  .crypto-politicos-container
    .crypto-politicos-table
    .table-row
    td:first-of-type {
    position: sticky;
    left: 0;
    background: #fff;
  }

  .crypto-politicos-container 
    .crypto-politicos-table 
    .menu-row th,
  .crypto-politicos-container 
    .crypto-politicos-table 
    .table-row td {
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}